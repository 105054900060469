import { IResponse } from '..';
import client from '../client';

export interface IUserResponse {
  id: string;
  email: string;
  authProviderId: string;
  password: string;

  firstname: string;
  lastname: string;

  verified: boolean;
  deactivated: boolean;
  onboarded: boolean;

  createdOn: Date;
  updatedOn: Date;
  role: string;
  metadata?: {
    lastActiveTeamId?: string;
  } | null;
}

interface IUserUpdate {
  firstname?: string;
  lastname?: string;
  email?: string;
  onboarded?: boolean;
  metadata?: {
    lastActiveTeamId?: string;
  } | null;
}

export interface IUserInsight {
  id: string;
  userId: string;
  type: string;
  createdAt: string;
  insight: any;
}

async function getMyUser() {
  return client.get<IResponse<IUserResponse>>('/user/me');
}

async function updateUser(userId: string, user: IUserUpdate) {
  return client.patch<IResponse<IUserResponse>>(`/user/${userId}`, user);
}

async function addUserInsight(userId: string, type: string, insights: any) {
  return client.put<IResponse<IUserResponse>>(
    `/user/${userId}/insight/${type}`,
    insights
  );
}

async function getUserInsights(userId: string) {
  return client.get<IResponse<IUserInsight[]>>(`/user/${userId}/insight`);
}

// async function getUsersOfTenant(tenantId: string) {
//   return client.get<IResponse<IUserResponse[]>>(`/user/tenantId:${tenantId}`);
// }

async function deleteUser(userId: string) {
  return client.delete<IResponse<IUserResponse[]>>(
    `/dsgvo/${userId}/delete-account`
  );
}

const api = {
  getMyUser,
  updateUser,
  addUserInsight,
  getUserInsights,
  // getUsersOfTenant,
  deleteUser,
};

export default api;
